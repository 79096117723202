// Fill in the variables and everything will be set on compile.
// If you want to add extra styles, you can edit this file

// Global font (body)
//$font_link: 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,700,800&display=swap';
//$font_family: 'Poppins', sans-serif;


@font-face {
	font-family: 'lato-heavy';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/lato-heavy.woff2') format('woff2'), url('/fonts/lato-heavy.woff') format('woff')
}

@font-face {
	font-family: 'lato-semibold';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/lato-semibold.woff2') format('woff2'), url('/fonts/lato-semibold.woff') format('woff')
}

@font-face {
	font-family: 'lato-light';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/lato-light.woff2') format('woff2'), url('/fonts/lato-light.woff') format('woff')
}

@font-face {
	font-family: 'lato-regular';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/lato-regular.woff2') format('woff2'), url('/fonts/lato-regular.woff') format('woff')
}

@font-face {
	font-family: 'lato-thin';
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/lato-thin.woff2') format('woff2'), url('/fonts/lato-thin.woff') format('woff')
}

body {
	font-family: 'lato-regular', serif
}

// Mobile side menu
$mobile_sidemenu_background: #B11116;
$mobile_sidemenu_link_color: #FFF;
$mobile_sidemenu_hover_background: rgba(0, 0, 0, 0.1);
$mobile_sidemenu_hover_color: #FFF;
$mobile_sidemenu_active_background: rgba(0, 0, 0, 0.1);
$mobile_sidemenu_active_color: #FFF;

// Page colors
$body_background: #FFFFFF;
$title_color: #B11116 !important;
$subtitle_color: #000 !important;
$border_color: 3px solid #B11116;

// Button colors
$button_background_color: #B11116 !important;
$button_color: #fff;
$sidemenu_icon_color_background: #F0C843 !important;
$sidemenu_active_icon_color_background: #B11116 !important;
$sidemenu_icon_color: #FFF !important;
$sidemenu_active_border_color: 5px solid #F0C843;

//top nav (desktop)
$top_nav_active_border_color: 2px solid #B11116;

//table colors (please use RGB colors for background otherwise opacity wont work correctly)
$table-supplier-bg-color-header: rgba(17, 86, 43, 1) !important;
$table-supplier-bg-color-header-text: #fff;

$table-supplier-bg-color-odd: rgba(177, 17, 22, 1);
$table-supplier-bg-color-even: rgba(177, 17, 22, 0.8);
$table-supplier-bg-color-text: #fff;


/* CSS supplier override starts here */

.supplier-title-color {
	color: $title_color;
}

.supplier-subtitle-color {
	color: $subtitle_color;
}

.supplier-body-background {
	background-color: $body_background;
}

.supplier-button-color {
	background: $button_background_color;
	color: $button_color;
}

.supplier-border-color {
	border: $border_color;
}

.supplier-sidemenu-icon-colors {
	background-color: $sidemenu_icon_color_background;
	color: $sidemenu_icon_color;
}

.supplier-active-sidemenu-icon-colors {
	background: $sidemenu_active_icon_color_background;
}

.supplier-active-sidemenu-line {
	border-left: $sidemenu_active_border_color;
}

ul.navbar-nav > li.active {
	border-bottom: $top_nav_active_border_color;
}

/* Table override colors */
.graph-table-supplier-color:nth-child(odd) {
	background: $table-supplier-bg-color-odd;
	color: $table-supplier-bg-color-text;
}

.graph-table-supplier-color:nth-child(even) {
	background: $table-supplier-bg-color-even;
	color: $table-supplier-bg-color-text;
}

.graph-table-supplier-color-header {
	background: $table-supplier-bg-color-header;
	color: $table-supplier-bg-color-header-text;
}

.btn-primary {
	color: #fff;
	background-color: $button_background_color;
}

.btn-only-bordered {
	background-color: white !important;
	border: 2px solid $button_background_color;
	color: $button_background_color;
}

.slide-down-color {
	background-color: $sidemenu_icon_color_background;
}

/* Table override colors */

.inner-content-body {
	border: solid 1px $sidemenu_icon_color_background;
}

.wsmainfull {
	border-bottom: solid 2px #efefef;
}

.content-card .supplier-sidemenu-icon-colors {
	color: white !important;
}

.content-card-dark {
	background-color: $button_background_color;
}
@media (max-width: 992px) {
	.content-card .btn.supplier-button-color, .submit-readings .btn.supplier-button-color,  {
		background-color: $sidemenu_icon_color_background;
		color: black;
	}
}

